.footer{
    position: relative;
    text-align: center;
    height: 80px;
    background: black;
    font-size: 1.2rem;
}

.footer .content{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.footer .content .text{
    opacity: .7;
}

.footer .content .logo{
    position: absolute;
    right: 30px;
}

.footer .content .logo a{
    font-size: 30px;
}





@media screen and (max-width: 960px) {

}