.projects{
    display: block;
}

.projects .info{
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects .cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.projects .card{
    display: flex;
    width: 340px;
    height: 200px;
    float: left;
    margin-left: 100px;
    margin-bottom: 100px;
    margin-right: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
}

.projects .card .img{
    width: 340px;
    height: 200px;
}

.projects .card img{
    width: 340px;
    height: 200px;
}

.projects .card:nth-last-child(-n+3){
    margin-bottom: 0;
}

@media screen and (max-width: 960px) {

}
