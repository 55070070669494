.about{
    display: block;
}

.about .info{
    display: flex;
    justify-content: center;
}

.about .img{
    padding-right: 5%;
 }

.about .img > img {
    width: 450px;
    height: 400px;
    border-radius: 20px;
 }

.about .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40%;
}

.about .text > p{
    text-align: justify;
}

.about .text .links{
    padding-top: 30px;
}

.about .text .links > a {
    padding-right: 20px;
}

@media screen and (max-width: 960px) {

}
