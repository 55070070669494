.contact{
    min-height: 650px;
    display: block
}


.contact .info{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact .form{
    display: grid;
    height: 100%;
    width: 420px;
    border-radius: 30px;
    text-align: center;
    margin-left: 470px;
}

.contact .form .form-text{
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
}

.contact .form input{
    text-align: center;
    border: 0;
    background-color: black;
    height: 50px;
    border-radius: 30px;
}

.contact .form .form-text input{
    width: 200px;
    height: 100%;
}

.contact .form .form-text input:first-child{
    margin-right: 20px;
}

.contact .form .form-text input:last-child{
    right: 0;
}

.contact .form .form-subject{
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
}

.contact .form .form-subject::-webkit-input-placeholder {
    font-weight: 500;
}
.contact .form .form-subject::-moz-placeholder {
    font-weight: 500;
}
.contact .form .form-subject::-ms-input-placeholder {
    font-weight: 500;
}

.contact .form .form-message{
    border: 0;
    resize: none;
    background-color: black;
    width: 100%;
    height: 130px;
    border-radius: 30px;
    padding: 1em;
    margin-bottom: 15px;
}

.contact .form .form-button{
    display: block;
    margin-right: auto;
    margin-left: auto;
    border: 0;
    background-color: black;
    height: 60px;
    width: 100%;
    border-radius: 30px;
}

.contact .contacts{
    width: 420px;
    height: 250px;
    display: flex;
    justify-content: center;
    background-color: black;
    border-radius: 30px;
    padding: 1em;
    flex-direction: column;
    margin-left: 50px;
}

.contact .social{
    display: flex;
    padding: 10px;
}

.contact .social .text{
    display: flex;
    padding-left: .8em;
    align-items: center;
}

.contact .contacts .icon{
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 960px){

}
