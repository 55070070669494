.skills{
    display: block;
}

.skills .content{
    display: grid;
    justify-content: center;
    align-items: center;
}

.skills .content .info{
    width: 960px;
    height: 310px;
    position: relative;
    display: flex;
    align-items: center;

}

.arrow-left, .arrow-right{
    fill: #000;
    background: #fff;
    border-radius: 100%;
    position: absolute;
    opacity: .2;
}

.arrow-left{
    left: -40px;
}

.arrow-right{
    right: -40px;
}

.arrow-left:hover, .arrow-right:hover{
    opacity: 1;
}

.slider{
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.slider::-webkit-scrollbar{
    display: none;
}

.slider .card{
    width: 300px;
    height: 300px;
    background-color: #fff;
    border-radius: 10px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.slider .card .icon{
    width: 100%;
    height: 170px;
    background-color: grey;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.slider .card .icon img{
    margin-top: 10px;
    height: 150px;
    border-radius: 10%;
    margin-left: 25%;
}

.slider .card .text{
    display: flex;
    justify-content: center;
    margin-top: 45px;
    color: #000;
    font-weight: 500;
    font-size: 1.5rem;
}

@media screen and (max-width: 960px) {

}
