@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: #fff;
    font-family: "Inter";
}

body{
    background-color: #333;
    font-size: 1rem;
}

.home, .about, .skills, .projects, .contact{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 965px
}

.home .content, .about .content, .skill .content, .projects .content{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title{
    text-align: center;
    text-decoration: 4px underline #ffffff;
    margin-bottom: 80px;
    font-size: 1.2rem;
}
