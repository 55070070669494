.navbar {
  position: sticky;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #0b0a0a;
}

.navbar .content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  margin: auto;
  height: 100%;
  background-color: transparent;
}

.navbar .title {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.nav-menu{
  display: flex;
  grid-gap: 10px;
  list-style: none;
  background: none;
  text-align: center;
  width: 60vw;
  justify-content: center;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.nav-item {
  height: 80px;
}

.button{
  background: #fff;
  border-radius: 10px;
  padding: 6px;
}

.contact-btn{
  display: flex;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 7px 6px;
  border-radius: 10px;
  cursor: pointer;
  border: 0;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar .content{
    justify-content: center;
  }

  .navbar .title {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 80px;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .9);
    width: 100%;
    /* height: 84vh;  if you want full navbar menu*/
    z-index: 999;
    text-align: center;
    transition: .3s;
    justify-content: unset;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-item:hover {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-item a{
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .nav-item a:hover{
    padding-bottom: 5px;
    background-color: #fff;
    color: #000;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
