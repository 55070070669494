.home .title{
  font-size: 55px;
  text-decoration: none;
  margin-bottom: 0;
}

.sub-title{
  padding-top: 8px;
  display: flex;
  display: block;
  font-size: 25px;
}

.arrow-animation{
  display: flex;
  background: none;
  position: relative;
  padding-top: 30px;
  cursor: pointer;
}

.fa-arrow-down{
  font-size: 130px;
  display: flex;
  justify-content: center;
  background: none;
}

.up-down-animation{
  animation: moveUpDown 2s linear infinite;
}

@media screen and (max-width: 960px) {

}

@keyframes moveUpDown {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px); /* Adjust the value to control the amount of movement */
  }
}
